<template>
  <v-container
    fluid
    class="mt-6 basko-next-gen-detail-container"
    v-if="articleSlug && article"
  >
    <router-link
      class="d-flex text-decoration-none previous-page black--text mb-4"
      to="/basko-for-next-gen"
    >
      <v-icon class="pr-2 ">$arrowBack</v-icon>
      <span class="h4 text-decoration-underline">{{
        $t("article.goBack")
      }}</span>
    </router-link>
    <v-row>
      <v-col cols="12" md="8">
        <h1 class="bng-article-title mb-4">{{ article.title.rendered }}</h1>
        <v-row class="mb-1 align-center justify-space-between">
          <v-col cols="6" md="4" order="1" class="d-flex align-start">
            <router-link
              :to="`/basko-for-next-gen/${categorySlug}`"
              class="text-decoration-none"
            >
              <h3 class="font-weight-bold primary--text">
                <v-icon class="mr-2" color="primary">$tag</v-icon>
                <span class="bng-article-category">{{ categoryName }}</span>
              </h3>
            </router-link>
          </v-col>
          <v-col
            cols="12"
            md="4"
            order="3"
            order-md="2"
            class="d-flex justify-start justify-md-center"
          >
            {{ $t("baskoForNextGen.detail.date", [formattedDate]) }}
          </v-col>
          <v-col
            cols="6"
            md="4"
            order="2"
            order-md="3"
            class="d-flex justify-end"
          >
            <ShareButtons
              :post="article"
              direction="bottom"
              :slug="article.slug"
              :extended="true"
            ></ShareButtons>
          </v-col>
        </v-row>
        <v-img
          v-if="
            article?.featured_image_url && article.featured_image_url.length > 0
          "
          :src="article.featured_image_url"
          :alt="`Basko For Next Gen: ${article.title.rendered}`"
          class="bng-article-image rounded-lg mb-4"
        ></v-img>
        <article v-html="article.content.rendered"></article>
      </v-col>
      <v-col cols="12" md="4">
        <BaskoNextGenSidebar></BaskoNextGenSidebar>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <BaskoNextGenFooter></BaskoNextGenFooter>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>
<style lang="scss">
.basko-next-gen-article-image {
  position: relative;
  .bng-article-title,
  .bng-article-subtitle,
  .bng-article-description {
    position: relative;
    z-index: 2;
    text-align: center;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}
.bng-article-category {
  font-size: 16px !important;
  line-height: 24px !important;
}
.bng-article-title {
  font-size: 36px !important;
  line-height: 53px !important;
}
.social-share,
.share-btn {
  cursor: pointer;
  cursor: -moz-pointer;
  cursor: -webkit-pointer;
}
.basko-next-gen-detail-container article {
  font-size: 20px;
  line-height: 30px;
}
</style>
<script>
import BaskoNextGenSidebar from "@/components/wordpress/BaskoNextGenSidebar.vue";
import BaskoNextGenFooter from "@/components/wordpress/BaskoNextGenFooter.vue";
import ShareButtons from "@/components/wordpress/ShareButtons.vue";

import CMService from "~/service/cmService";
export default {
  name: "BaskoNextGenCategory",
  components: { BaskoNextGenSidebar, BaskoNextGenFooter, ShareButtons },
  data() {
    return {
      article: null
    };
  },
  methods: {
    async fetchArticle() {
      const res = await CMService.getCustomPostBySlug(
        "basko-next-gen",
        this.articleSlug
      );
      if (res && res.length > 0) {
        this.article = res[0];
      }
    }
  },

  computed: {
    articleSlug() {
      return this.$route.params.articleSlug;
    },
    categorySlug() {
      return this.article &&
        this.article.category_names &&
        this.article.category_names.length > 0
        ? this.article.category_names[0].slug
        : null;
    },
    categoryName() {
      return this.article &&
        this.article.category_names &&
        this.article.category_names.length > 0
        ? this.article.category_names[0].name
        : null;
    },
    formattedDate() {
      return this.$dayjs(this.article.date).format("DD/MM/YYYY");
    }
  },
  async created() {
    await this.fetchArticle();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      console.log("Route changed", to.params.articleSlug);
    }
  }
};
</script>
